<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.team.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.team.back' | translate}}</button>
                </div>
                <div class="col-md-3 offset-md-6">
                    <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
                </div>
            
            </div>
        
        </vuestic-widget>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="'view.team.new' | translate">
                    <form @submit.prevent="onSubmit">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                                                <div class="input-group">
                                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.team.fields.name' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('description'), 'valid': isSuccessfulDescriptionValid}">
                                                <div class="input-group">
                                                    <input id="description" name="description" v-model="description" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="description">{{'view.team.fields.description' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('description')" class="help text-danger">
                                                        {{ errors.first('description') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.team.save')}}</button>
                                </div>
                            </div>
                        </div>
                    
                    </form>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name:     'form-elements',
        data() {
            return {
                name:        '',
                description: ''
            }
        },
        methods:  {
            ...mapActions([
                'storeTeam',
                'addToastMessage',
            ]),
            onSubmit() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.storeTeam({name: this.name, description: this.description})
                            .then((data) => {
                                this.addToastMessage({
                                    text: data.message,
                                    type: 'success'
                                })
                                this.$router.replace('/team')
                            })
                            .catch((data) => {
                                this.error = data.message
                            })
                    }
                });

            },
            clear(field) {
                this[field] = ''
            },
            back () {
                this.$router.go(-1);
                // this.$router.push('/')
            },
        },
        computed: {
            isSuccessfulNameValid() {
                let isValid = false
                if (this.formFields.name) {
                    isValid = this.formFields.name.validated && this.formFields.name.valid
                }
                return isValid
            },
            isSuccessfulDescriptionValid() {
                let isValid = false
                if (this.formFields.description) {
                    isValid = this.formFields.description.validated && this.formFields.description.valid
                }
                return isValid
            },
        },
    }
</script>
